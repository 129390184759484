import { Link } from "gatsby";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "../Button";

import { graphql, StaticQuery } from "gatsby";
import TwoColumnHomeSection from "./TwoColumnHomeSection";
import { GTM_LEAD_UMOW_SIE } from "../../config/gtm_classes";

const Laser = ({ className = "", graph, reverse = true }) => (
  <TwoColumnHomeSection
    reverse={reverse}
    className={className}
    h2="Laserowe Usuwanie Zmian Skórnych"
    H5={() => (
      <>
        Zabieg laserowego usuwania łagodnych zmian np.brodawek, włókniaków,
        kurzajek polega na skierowaniu promienia lasera na usuwane tkanki celem
        ich odparowania. Procedurę wykonujemy przy użyciu lasera CO2.
      </>
    )}
    Text={() => (
      <>
        Zabieg nie wymaga użycia skalpela, ani innych narzędzi chirurgicznych a
        laser CO2 stanowi kluczowe narzędzie w dermatologii, umożliwiając
        szybkie usuwanie wszelkich niezłośliwych zmian skórnych w sposób
        precyzyjny i bezpieczny. Dzięki wydajnemu procesowi leczenia,
        charakteryzującemu się minimalnymi uszkodzeniami tkanek, pacjenci mogą
        cieszyć się suchymi ranami, które nie wymagają obszernej opieki.
        Wszystkie nieinwazyjne metody usuwania zmian skórnych w tym usuwanie
        laserowe znamion zarezerwowane są do usuwania zmian łagodnych takich
        m.in. jak brodawki łojotokowe, włókniaki, kępki żółte czy kurzajki, a
        decyzję o tym, czy znamię może zostać usunięte laserowo zawsze podejmuje
        lekarz!
      </>
    )}
    Buttons={() => (
      <>
        <Button>
          <AnchorLink
            href="#cennik"
            title="Umów się"
            className={GTM_LEAD_UMOW_SIE}
          >
            Umów się
          </AnchorLink>
        </Button>
        <Button type="secondary">
          <Link
            to="/laserowe-usuwanie-znamion"
            title="Laserowe Usuwanie znamion"
          >
            Laserowe Usuwanie znamion
          </Link>
        </Button>
      </>
    )}
    imageInfo={{
      image: graph.Image,
      alt: "Laserowe usuwanie znamion",
      title: "Laserowe usuwanie znamion",
    }}
  />
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        Image: allFile(
          filter: { relativePath: { regex: "/home_laser.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 337, maxHeight: 300, quality: 25) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Laser
        graph={{
          Image: data.Image.nodes[0],
        }}
        {...props}
      />
    )}
  />
);
