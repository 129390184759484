import { Link } from "gatsby";
import React, { useEffect } from "react";
import { IS_GIFT_CARD_GLOBAL } from "../../config/featureFlag";
import { GTM_LEAD_GIFT_PRICELIST } from "../../config/gtm_classes";
import AccordionPrices from "../AccordionPrices";
import Alert from "../Alert";
import BubbleSection from "../BubbleSection";
import Button from "../Button";
import IconImage from "../../img/nizsze_ceny.svg";

import useInViewPort from "../useInViewPort";

const GiftCardCTA = ({ city = "all" }) => (
  <Button type="primary">
    <Link
      to={`/bon-podarunkowy`}
      title="Kup Bon Podarunkowy"
      className={`${GTM_LEAD_GIFT_PRICELIST}`}
    >
      Podaruj Bon
    </Link>
  </Button>
);
const ProgramProfilaktykiCTA = ({ city = "all" }) => (
  <Button type="primary" size="S">
    <Link to={`/program-profilaktyki`} title="Program Profilaktyki">
      Jak to działa?
    </Link>
  </Button>
);

const PriceList = ({
  className = "",
  data,
  ifGistAvailable = true,
  ifProgramProfilaktykiAvailable = false,
}) => {
  const [nodeRef, isVisible] = useInViewPort();

  useEffect(() => {
    if (isVisible && typeof window !== "undefined") {
      const mydrScript = document.getElementsByTagName("script")[0];

      const js = document.createElement("script");

      js.src = "https://mydr.pl/static/mydr-pp.min.js";

      mydrScript.parentNode.insertBefore(js, mydrScript);

      js.onload = () => {
        const PatientsPlugin = new window.PatientsPlugin();

        PatientsPlugin.init({
          app: "https://mydr.pl/patients_plugin",
          plugin: "https://mydr.pl/static",
        });
      };
    }
  }, [isVisible]);

  return (
    <div ref={nodeRef} className={className}>
      <a className="anchor" id="cennik"></a>
      <BubbleSection
        h2="Cennik naszych usług"
        h5="Rozwiń kategorię, aby umówić się online"
        color="gray"
      >
        <div className="container">
          {ifProgramProfilaktykiAvailable && (
            <Alert
              withImage={true}
              iconImage={IconImage}
              withBorder
            >
              Dołącz do{" "}
              Programu Profilaktyki "Twoje Znamiona pod kontrolą" <strong>po
              wizycie</strong> zgodnie z zaleceniami lekarza i{" "}
              <strong>zapłać mniej</strong> za badanie oraz zyskaj{" "}
              zniżki na nasze usługi usuwania znamion.
            </Alert>
          )}
          <br />
          <AccordionPrices priceList={data} />
          {ifGistAvailable && IS_GIFT_CARD_GLOBAL && (
            <Alert button={GiftCardCTA} withImage={false}>
              <strong>Pomyśl o najbliższych</strong> i już dziś skieruj ich na
              badania profilaktyczne w kierunku nowotworów skóry.
            </Alert>
          )}
        </div>
      </BubbleSection>
    </div>
  );
};

export default PriceList;
