import React from "react";

import IconImage from "../img/ic_question.svg";
import "../styles/alert.scss";

const Alert = ({
  children,
  ctaOffset = 0,
  button = (x) => null,
  buttonProps = {},
  withImage = true,
  withBorder = false,
  iconImage,
}) => {
  const Button = button;
  const className = 'alert ' + (withBorder? 'alert--with-border' : '')
  
  return (
    <div className={className}>
      {withImage && <img src={iconImage || IconImage} alt="Question" />}
      <div className="alert_body">{children}</div>
      <Button ctaOffset={ctaOffset} {...buttonProps} />
    </div>
  );
};
export default Alert;
